define("discourse/plugins/discourse-ford-plugin/discourse/connectors/user-card-after-metadata/ford-profile-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isEmployee", component.get("user.ford_profile.strUserType") === "Employee");
    }
  };
});