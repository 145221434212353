define("discourse/plugins/discourse-ford-plugin/discourse/connectors/admin-user-controls-after/admin-send-password-reset", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner", "I18n"], function (_exports, _ajax, _ajaxError, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      async sendPasswordReset(username) {
        const dialog = (0, _getOwner.getOwner)(this).lookup("service:dialog");
        if (this.model.staff) {
          dialog.alert(_I18n.default.t("admin.ford_password_reset.other_staff"));
          return;
        }
        if (!this.currentUser.email) {
          await this.currentUser.checkEmail();
        }
        dialog.yesNoConfirm({
          message: _I18n.default.t("admin.ford_password_reset.description", {
            username,
            email: this.currentUser.email
          }),
          didConfirm: () => {
            return (0, _ajax.ajax)(`/admin/ford_staff_password_reset/${username}.json`, {
              type: "POST"
            }).then(() => dialog.alert(_I18n.default.t("admin.ford_password_reset.success"))).catch(_ajaxError.popupAjaxError);
          }
        });
      }
    }
  };
});