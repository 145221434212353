define("discourse/plugins/discourse-ford-plugin/discourse/connectors/login-after-modal-footer/ford-create-account", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/modal/forgot-password", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _forgotPassword, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FordCreateAccountButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    fordCreateAccount() {
      this.modal.show(_forgotPassword.default);
    }
    static #_2 = (() => dt7948.n(this.prototype, "fordCreateAccount", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="btn-large"
          id="ford-new-account-link"
          @action={{this.fordCreateAccount}}
          @label="create_account.title"
          tabindex="3"
        />
      
    */
    {
      "id": "/eyKzICR",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-large\"],[24,1,\"ford-new-account-link\"],[24,\"tabindex\",\"3\"]],[[\"@action\",\"@label\"],[[30,0,[\"fordCreateAccount\"]],\"create_account.title\"]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ford-plugin/discourse/connectors/login-after-modal-footer/ford-create-account.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FordCreateAccountButton;
});