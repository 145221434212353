define("discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/admin-user-controls-after/admin-send-password-reset", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    action=(action "sendPasswordReset")
    actionParam=model.username
    icon="key"
    label="admin.ford_password_reset.title"
  }}
  */
  {
    "id": "9RFmd6ne",
    "block": "[[[1,[28,[35,0],null,[[\"action\",\"actionParam\",\"icon\",\"label\"],[[28,[37,1],[[30,0],\"sendPasswordReset\"],null],[30,0,[\"model\",\"username\"]],\"key\",\"admin.ford_password_reset.title\"]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/admin-user-controls-after/admin-send-password-reset.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/admin-user-controls-after/admin-send-password-reset.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});