define("discourse/plugins/discourse-ford-plugin/discourse/initializers/ford-profile-sync", ["exports", "@ember/application", "jquery", "discourse/components/modal/forgot-password", "discourse/lib/plugin-api", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _application, _jquery, _forgotPassword, _pluginApi, _iconLibrary, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "ford-profile-sync";
  function initializeFordProfileSync(api) {
    api.modifyClass("route:application", {
      pluginId: PLUGIN_ID,
      actions: {
        showCreateAccount() {
          (0, _application.getOwner)(this).lookup("service:modal").show(_forgotPassword.default);
        }
      }
    });
    api.modifyClass("component:group-member-dropdown", dt7948.p({
      pluginId: PLUGIN_ID,
      belongsToFord(fordGroups, fordGroupsMember) {
        if (!fordGroups || !fordGroupsMember) {
          return false;
        }
        return fordGroups.some(fg => fordGroupsMember.includes(fg));
      },
      didInsertElement() {
        if (this.belongsToFord) {
          this.set("headerIcon", ["users"]);
          this.set("isDisabled", true);
        }
      }
    }, [["method", "belongsToFord", [(0, _decorators.default)("group.ford_groups", "member.ford_groups_member")]]]));
    api.decorateWidget("poster-name:after", helper => {
      const customFields = helper.attrs.userCustomFields;
      if (!customFields) {
        return;
      }
      const items = [];
      if (customFields.ford_strCurrentRole && customFields.ford_strUserType !== "Employee") {
        items.push(helper.h("span.ford-role", customFields.ford_strCurrentRole));
      }
      items.push(helper.h("div.break"));
      if (customFields.ford_strDealershipName) {
        items.push(helper.h("span.ford-dealership", customFields.ford_strDealershipName));
      }
      if (customFields.ford_strCity && customFields.ford_strState) {
        items.push(helper.h("span.ford-location", [(0, _iconLibrary.iconNode)("map-marker-alt"), `${customFields.ford_strCity}, ${customFields.ford_strState}`]));
      }
      return items;
    });
    api.modifyClass("model:group", {
      pluginId: PLUGIN_ID,
      init() {
        this._super(...arguments);
        this.ford_groups = this.ford_groups || [];
      },
      asJSON() {
        const attrs = this._super(...arguments);
        attrs["custom_fields"] = {
          ford_groups: this.ford_groups
        };
        return attrs;
      }
    });
    api.decorateCooked($elem => {
      const dialog = api.container.lookup("service:dialog");
      (0, _jquery.default)(".onebox.fordinternaldocument .secure-icon", $elem).click(event => {
        dialog.alert(event.currentTarget.title);
      });
    });
    const currentUser = api.getCurrentUser();
    if (currentUser?.ford_duplicated_email) {
      api.addGlobalNotice(_discourseI18n.default.t("ford.duplicate_email_warning", {
        email: currentUser.ford_duplicated_email
      }), "ford-duplicate-email", {
        dismissable: true,
        dismissDuration: moment.duration("1", "week")
      });
    }
  }
  var _default = _exports.default = {
    name: "ford-profile-sync",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.ford_plugin_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", initializeFordProfileSync);
      }
    }
  };
});