define("discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/user-card-before-badges/data-source", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{html-safe
    (i18n
      "ford.data_source_info" link=siteSettings.ford_profile_sync_data_source_url
    )
  }}
  */
  {
    "id": "JDmuOCbX",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"ford.data_source_info\"],[[\"link\"],[[30,0,[\"siteSettings\",\"ford_profile_sync_data_source_url\"]]]]]],null]],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/user-card-before-badges/data-source.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ford-plugin/discourse/templates/connectors/user-card-before-badges/data-source.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});