define("discourse/plugins/discourse-ford-plugin/discourse/connectors/admin-user-controls-after/profile-sync-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner", "I18n"], function (_exports, _ajax, _ajaxError, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      fetchUserProfile(username) {
        this.set("loading", true);
        (0, _ajax.ajax)(`/profile_sync/update/${username}`, {
          method: "POST"
        }).then(() => {
          const dialog = (0, _getOwner.getOwner)(this).lookup("service:dialog");
          dialog.alert(_I18n.default.t("admin.profile_sync_button.message"));
          this.set("loading", false);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  };
});